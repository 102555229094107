import $ from "jquery";

$('#js-hamburger').on('click', (event) => {
  $(event.currentTarget).toggleClass('-active');
  $('#js-accordionNav').slideToggle();
  return false;
});

$('.js-accordionNavItem').on('click', (event) => {
  $('#js-hamburger').removeClass('-active');
  $('#js-accordionNav').slideUp();
});
