import $ from "jquery";

$('.js-archiveMenuTab').on('click', (event) => {
  $('.js-archiveMenuTab').removeClass('-active');
  $('.js-archiveMenuTab').parent().removeClass();
  $(event.currentTarget).addClass('-active');
  let index = $('.js-archiveMenuTab').index(event.currentTarget);
  $('.js-archiveMenuContent').removeClass('-active');
  $('.js-archiveMenuContent').eq(index).addClass('-active');
  if(index == 0){
    $(event.currentTarget).parent().addClass('-topics');
  } else if(index == 1){
    $(event.currentTarget).parent().addClass('-news');
  } else if(index == 2){
    $(event.currentTarget).parent().addClass('-event');
  } else if(index == 3){
    $(event.currentTarget).parent().addClass('-report');
  }
   return false;
});
