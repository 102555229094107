import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const swiper = new Swiper("#js-homeMvSwiper", {
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  spaceBetween: 0,
  speed: 2000,
  effect: "fade",
});
