const toTop = document.getElementById('js-toTop');

window.addEventListener('scroll', function() {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;

  if (scrollTop > 100) {
    toTop.classList.add('-active');
  } else {
    toTop.classList.remove('-active');
  }
});
