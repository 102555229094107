import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const swiper = new Swiper("#js-homeMovieSwiper", {
  loop: true,
  navigation: {
    nextEl: "#js-homeMovieSwiper .swiper-button-next",
    prevEl: "#js-homeMovieSwiper .swiper-button-prev",
  },
  pagination: {
    clickable: true,
    el: "#js-homeMovieSwiper .swiper-pagination",
  },
  spaceBetween: 0,
  speed: 400,
});
